import 'i18n';
import {useState, useEffect} from 'react';
import {Drawer, InputGroup} from '@blueprintjs/core';
import {useTranslation} from 'react-i18next';
import SimpleKeyBoard from 'react-simple-keyboard';
import 'react-simple-keyboard/build/css/index.css';
import {gql, QueryResult, useQuery} from '@apollo/client';
import styles from './search.module.scss';
import {Product} from 'components/product';
import {ProductInterface} from 'interfaces/product';
import Arrow from 'components/button/assets/arrow';
import Slider, {CustomArrowProps} from 'react-slick';
import {Button} from 'components/button';
import {ProductDrawer} from 'components/product-drawer';
import {useHistory} from 'react-router-dom';
import {SearchCategories} from 'components/search-categories';

export const SEARCH_PRODUCTS = gql`
  query ($name: String!, $limit: Int, $offset: Int) {
    search(name: $name, limit: $limit, offset: $offset) {
      items {
        id
        name
        article
        currentPrice
        oldPrice
        hasDiscount
        thumbnail
        categoryId
      }
      count
    }
  }
`;

export const Search = () => {
  const {t} = useTranslation();
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
  const [query, setQuery] = useState<string>('');
  const [layoutName, setLayoutName] = useState<string>('default');
  const [shift, setShift] = useState<boolean>(false);
  const [lock, setLock] = useState<boolean>(false);
  const [productDrawerOpen, setProductDrawerOpen] = useState<boolean>(false);
  const [openProduct, setOpenProduct] = useState<ProductInterface | null>(null);
  const history = useHistory();

  const onKeyPress = (input: string) => {
    if (input === '{tab}') {
      return;
    }

    if (input === '{enter}') {
      handleClose();
    }

    if (input === '{bksp}') {
      setQuery(query.slice(0, -1));
      return;
    }

    if (input === '{space}') {
      setQuery(query + ' ');
      return;
    }

    if (input === '{shift}') {
      setShift(true);
      setLayoutName(lock ? 'default' : 'shift');
      return;
    }

    if (input === '{lock}') {
      setLock(!lock);
      setLayoutName(lock ? 'default' : 'shift');
      return;
    }

    if (input === '{enter}') {
      return;
    }

    setQuery(query + input);

    if (shift) {
      setLayoutName(lock ? 'shift' : 'default');
      setShift(false);
    }
  };

  const handleOpen = () => {
    setDrawerOpen(true);
  };

  const handleClose = () => {
    setQuery('');
    setDrawerOpen(false);
  };

  const handleOpenDrawer = (product: ProductInterface) => {
    setOpenProduct(product);
    setProductDrawerOpen(true);
  };

  const PreviousArrow = (props: CustomArrowProps) => {
    const {onClick} = props;

    return (
      <div className={styles.prevArrow} onClick={onClick}>
        <Arrow />
      </div>
    );
  };

  const NextArrow = (props: CustomArrowProps) => {
    const {onClick} = props;

    return (
      <div className={styles.nextArrow} onClick={onClick}>
        <Arrow angle={180} />
      </div>
    );
  };

  const sliderSettings = {
    dots: false,
    arrows: true,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 5,
    draggable: true,
    swipeToSlide: true,
    nextArrow: <NextArrow />,
    prevArrow: <PreviousArrow />,
    className: styles.resultsList,
    variableWidth: true,
  };

  interface Response {
    search: {
      items: ProductInterface[];
      count: number;
    };
  }

  const {data}: QueryResult<Response> = useQuery<Response>(SEARCH_PRODUCTS, {
    variables: {
      name: query,
    },
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    localStorage.setItem('query', query);
  }, [query]);

  return (
    <>
      <InputGroup
        placeholder={t('search.placeholder')}
        fill={true}
        round={true}
        defaultValue={query}
        onClick={handleOpen}
        className={styles.search}
      />
      <Drawer
        size={`100%`}
        position="bottom"
        transitionDuration={0}
        hasBackdrop={false}
        isOpen={drawerOpen}
        className={styles.drawer}
      >
        <div className={styles.drawerHeader} onClick={handleClose} />
        <div className={styles.drawerBody}>
          <div className={styles.close}>
            <Button onClick={handleClose} text={t('button.close')} />
          </div>
          <div className={styles.results}>
            <div className={styles.categories}>
              <div>
                <h3 className={styles.title}>{t('search.categories')}</h3>
                <span className={styles.count} />
              </div>
              {query && <SearchCategories query={query} handleClose={handleClose} />}
            </div>
            <div className={styles.products}>
              {data && (
                <>
                  <h3 className={styles.title}>{t('search.products')}</h3>
                  <span className={styles.count}>
                    ({data.search.count} {t('search.results')})
                  </span>
                  <Slider {...sliderSettings}>
                    {data &&
                      data.search.items.map((product: ProductInterface) => (
                        <Product
                          key={product.id}
                          product={product}
                          handleClick={() => handleOpenDrawer(product)}
                          compact={true}
                        />
                      ))}
                  </Slider>
                  {data.search.count > 0 && (
                    <Button
                      onClick={() => {
                        setDrawerOpen(false);
                        history.push(`/search/${query}`);
                      }}
                      text={t('search.all_results')}
                      arrow={'right'}
                    />
                  )}
                </>
              )}
            </div>
          </div>
          <SimpleKeyBoard
            className={styles.keyboard}
            layoutName={layoutName}
            onKeyPress={onKeyPress}
            layout={{
              default: [
                '1 2 3 4 5 6 7 8 9 0 {bksp}',
                'q w e r t y u i o p',
                'a s d f g h j k l',
                'z x c v b n m',
                '{space}',
              ],
              shift: [
                '1 2 3 4 5 6 7 8 9 0 {bksp}',
                'q w e r t y u i o p',
                'a s d f g h j k l',
                'z x c v b n m',
                '{space}',
              ],
            }}
            mergeDisplay={true}
            display={{
              '{bksp}': t('keyboard.delete'),
            }}
          />
        </div>
      </Drawer>
      {openProduct && (
        <ProductDrawer
          isOpen={productDrawerOpen}
          handleClose={() => setProductDrawerOpen(false)}
          openProduct={openProduct}
          query={query}
        />
      )}
    </>
  );
};
