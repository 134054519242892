import styles from './button.module.scss';
import Arrow from './assets/arrow';
import Home from './assets/home';

interface ButtonProps {
  text?: string;
  large?: boolean;
  arrow?: 'left' | 'right' | 'home';
  onClick: (ev: React.MouseEvent<HTMLButtonElement>) => void;
  inverted?: boolean;
}

export const Button = ({text, large, arrow, onClick, inverted}: ButtonProps) => {
  const buttonStyles = [styles.base];

  if (large) {
    buttonStyles.push(styles.large);
  }

  if (inverted) {
    buttonStyles.push(styles.inverted);
  }

  return (
    <button className={buttonStyles.join(' ')} onClick={onClick}>
      {arrow === 'home' && <Home />}
      {arrow === 'left' && <Arrow stroke="white" fill="#8e3193" />}
      <span>{text}</span>
      {arrow === 'right' && <Arrow stroke="white" fill="none" angle={180} />}
    </button>
  );
};
