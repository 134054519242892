import React from 'react';
import {Search} from 'components/search';
import {useTranslation} from 'react-i18next';
import {LanguageSwitcher} from 'components/language-switcher';
import styles from './layout.module.scss';
import Logo from './assets/logo.png';
import {Button} from 'components/button';
import {Link, useHistory} from 'react-router-dom';

interface LayoutProps {
  children: React.ReactNode;
}

export const Layout = ({children}: LayoutProps) => {
  const {t} = useTranslation();
  const history = useHistory();

  const HandleClickHome = () => {
    history.push('/');
  };

  const HandleClickBack = () => {
    if (history.length === 0) {
      return;
    }
    history.goBack();
  };

  return (
    <>
      <div className={styles.header}>
        <div className={styles.back}>
          <Button
            text={t('breadcrumbs.home')}
            large={true}
            arrow="home"
            onClick={HandleClickHome}
          />
          <Button
            text={t('button.back')}
            large={true}
            arrow="left"
            onClick={HandleClickBack}
            inverted={true}
          />
        </div>
        <div className={styles.search}>
          <Search />
        </div>
        <div className={styles.logo}>
          <Link to={'/'}>
            <img alt="majaiundarzam.lv" src={Logo} />
          </Link>
        </div>
        <div className={styles.language}>
          <LanguageSwitcher />
        </div>
      </div>
      {children}
    </>
  );
};
