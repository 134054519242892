import React, {useState} from 'react';
import {gql, useQuery, QueryResult} from '@apollo/client';
import {ProductInterface} from 'interfaces/product';
import Slider, {CustomArrowProps, Settings} from 'react-slick';
import {Product} from 'components/product';
import styles from './discount-list.module.scss';
import Arrow from 'components/button/assets/arrow';
import {ProductDrawer} from 'components/product-drawer';
import {CategoryInterface} from 'interfaces/category';

export const GET_DISCOUNT = gql`
  query ($limit: Int, $categoryId: Int) {
    discount(limit: $limit, categoryId: $categoryId) {
      items {
        id
        name
        article
        currentPrice
        oldPrice
        hasDiscount
        thumbnail
        categoryId
      }
      count
    }
  }
`;

interface DiscountListProps {
  limit: number;
  category?: CategoryInterface;
}

export const DiscountList = ({limit, category}: DiscountListProps) => {
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
  const [openProduct, setOpenProduct] = useState<ProductInterface | null>(null);

  interface Response {
    discount: {
      items: ProductInterface[];
      count: number;
    };
  }

  const PreviousArrow = (props: CustomArrowProps) => {
    const {onClick} = props;

    return (
      <div className={styles.prevArrow} onClick={onClick}>
        <Arrow />
      </div>
    );
  };

  const NextArrow = (props: CustomArrowProps) => {
    const {onClick} = props;

    return (
      <div className={styles.nextArrow} onClick={onClick}>
        <Arrow angle={180} />
      </div>
    );
  };

  const discountSliderSettings: Settings = {
    dots: false,
    arrows: true,
    infinite: false,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 3,
    draggable: true,
    swipeToSlide: true,
    nextArrow: <NextArrow />,
    prevArrow: <PreviousArrow />,
    className: styles.discountList,
    autoplay: true,
    autoplaySpeed: 5000,
    lazyLoad: 'ondemand',
  };

  const {data}: QueryResult<Response> = useQuery<Response>(GET_DISCOUNT, {
    variables: {
      limit,
      categoryId: category?.id,
    },
    fetchPolicy: 'no-cache',
  });

  const handleOpenDrawer = (product: ProductInterface) => {
    setOpenProduct(product);
    setDrawerOpen(true);
  };

  return (
    <div>
      <Slider {...discountSliderSettings}>
        {data &&
          data.discount.items.map((product: ProductInterface) => (
            <Product
              key={product.id}
              product={product}
              handleClick={() => handleOpenDrawer(product)}
              compact={true}
            />
          ))}
      </Slider>
      {openProduct && (
        <ProductDrawer
          isOpen={drawerOpen}
          handleClose={() => setDrawerOpen(false)}
          openProduct={openProduct}
          discount={true}
        />
      )}
    </div>
  );
};
