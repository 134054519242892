import 'i18n';
import {useTranslation} from 'react-i18next';
import styles from './language-switcher.module.scss';
import FlagLV from './assets/latvia.svg';
import FlagRU from './assets/russia.svg';
import FlagEN from './assets/united-kingdom.svg';

export const LanguageSwitcher = () => {
  const {i18n} = useTranslation();

  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
  };

  return (
    <>
      <img
        alt="LV"
        className={(i18n.language === 'lv'
          ? [styles.languageSwitcher, styles.current]
          : [styles.languageSwitcher]
        ).join(' ')}
        src={FlagLV}
        onClick={() => changeLanguage('lv')}
      />
      <img
        alt="RU"
        className={(i18n.language === 'ru'
          ? [styles.languageSwitcher, styles.current]
          : [styles.languageSwitcher]
        ).join(' ')}
        src={FlagRU}
        onClick={() => changeLanguage('ru')}
      />
      <img
        alt="EN"
        className={(i18n.language === 'en'
          ? [styles.languageSwitcher, styles.current]
          : [styles.languageSwitcher]
        ).join(' ')}
        src={FlagEN}
        onClick={() => changeLanguage('en')}
      />
    </>
  );
};
