export const timer = () => {
  const timeout = 1000 * 60 * 5;
  const refreshPage = () => {
    window.location.href = '/';
  };

  let time = setTimeout(refreshPage, timeout);

  const resetTimer = () => {
    clearTimeout(time);
    time = setTimeout(refreshPage, timeout);
  };

  window.addEventListener('load', resetTimer, true);
  const events = ['mousedown', 'mousemove', 'keypress', 'scroll', 'touchstart'];
  events.forEach((name) => {
    document.addEventListener(name, resetTimer, true);
  });
};
