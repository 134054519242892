import {RouteComponentProps} from 'react-router-dom';
import {QueryResult, useQuery} from '@apollo/client';
import {SEARCH_PRODUCTS} from 'components/search';
import {ProductInterface} from 'interfaces/product';
import {useState} from 'react';
import {Product} from 'components/product';
import {Pagination} from 'components/pagination';
import {ProductDrawer} from 'components/product-drawer';
import {Breadcrumbs} from 'components/breadcrumbs';
import styles from 'components/product-list/product-list.module.scss';
import 'i18n';
import {useTranslation} from 'react-i18next';

export const SearchPage = ({match}: RouteComponentProps<{query: string}>) => {
  const {t} = useTranslation();
  const [offset, setOffset] = useState<number>(0);
  const limit = 10;
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
  const [openProduct, setOpenProduct] = useState<ProductInterface | null>(null);

  const handlePageChange = (selectedItem: {selected: number}) => {
    setOffset(limit * selectedItem.selected);
  };

  const handleOpenDrawer = (product: ProductInterface) => {
    setOpenProduct(product);
    setDrawerOpen(true);
  };

  interface Response {
    search: {
      items: ProductInterface[];
      count: number;
    };
  }

  const {data}: QueryResult<Response> = useQuery<Response>(SEARCH_PRODUCTS, {
    variables: {
      name: match.params.query,
      limit,
      offset,
    },
    fetchPolicy: 'no-cache',
  });

  return (
    <>
      <Breadcrumbs />
      <div className={styles.header}>
        {data && data.search.count > limit && (
          <div className={styles.headerContainer}>
            <h1>
              {t('search.search_results')}
              <span>{`"${match.params.query}"`}</span>
              <small>
                ({t('search.found')} <span>{data.search.count}</span> {t('search.products')})
              </small>
            </h1>
            <Pagination
              pageCount={Math.ceil(data.search.count / limit)}
              handlePageChange={handlePageChange}
            />
          </div>
        )}
      </div>
      <div className={styles.productList}>
        {data &&
          data.search.items.map((product: ProductInterface) => (
            <Product
              key={product.id}
              product={product}
              handleClick={() => handleOpenDrawer(product)}
            />
          ))}
        {openProduct && (
          <ProductDrawer
            isOpen={drawerOpen}
            handleClose={() => setDrawerOpen(false)}
            openProduct={openProduct}
            query={match.params.query}
          />
        )}
      </div>
    </>
  );
};
