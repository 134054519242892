import 'i18n';
import {useTranslation} from 'react-i18next';
import styles from './product.module.scss';
import {ProductInterface} from 'interfaces/product';
import placeholder from './assets/placeholder.png';
import React from 'react';

interface ProductProps {
  product: ProductInterface;
  compact?: boolean;
  handleClick?: (ev: React.MouseEvent<HTMLDivElement>) => void;
}

export const Product = ({product, compact, handleClick}: ProductProps) => {
  const {t} = useTranslation();

  return (
    <>
      <div
        className={(compact ? [styles.product, styles.compact] : [styles.product]).join(' ')}
        onClick={handleClick}
      >
        <div className={styles.image}>
          {product.hasDiscount && <span className={styles.sale}>{t('product.sale')}</span>}
          <img src={product.thumbnail ?? placeholder} alt={product.name} width={120} />
        </div>
        <h3 className={styles.title}>{product.name}</h3>
        <div className={styles.article}>
          {t('product.article')} {product.article}
        </div>
        <div className={styles.price}>
          {product.hasDiscount ? (
            <>
              <span className={styles.currentPrice}>{product.currentPrice}</span>
              <span className={styles.oldPrice}>{product.oldPrice}</span>
            </>
          ) : (
            <span>{product.currentPrice}</span>
          )}
        </div>
      </div>
    </>
  );
};
