import {CSSProperties} from 'react';

interface ArrowProps {
  angle?: number;
  fill?: string;
  stroke?: string;
  width?: number;
  height?: number;
  strokeWidth?: number;
}

const Arrow = ({
  angle = 0,
  fill = '#8e3193',
  stroke = '#8e3193',
  width = 50,
  height = 50,
  strokeWidth = 2,
}: ArrowProps) => {
  const transform = `rotate(${angle} 25 25)`;

  const svgStyle: CSSProperties = {
    width: width,
    height: height,
  };
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" version="1.1" style={svgStyle}>
      <path
        d="M 23.548499,47.613873 A 3.6792616,3.8700893 0 0 1 20.930044,46.469796 L 3.2454189,27.754947 a 3.6792616,3.8700893 0 0 1 0,-5.452025 L 20.930044,3.602202 a 3.6793414,3.8701732 0 1 1 5.223477,5.452022 L 11.087349,25.028935 26.180378,41.017771 a 3.6792616,3.8700893 0 0 1 -2.605025,6.596102 z"
        fill={fill}
        stroke={stroke}
        strokeWidth={strokeWidth}
        transform={transform}
      />
    </svg>
  );
};

export default Arrow;
