import {ProductInterface} from 'interfaces/product';
import Arrow from 'components/button/assets/arrow';
import Slider, {CustomArrowProps, Settings} from 'react-slick';
import styles from './product-image.module.scss';
import {createRef, useState, useEffect} from 'react';
import {ProductLightbox} from 'components/product-lightbox';

interface ProductImageProps {
  product: ProductInterface;
}

export const ProductImage = ({product}: ProductImageProps) => {
  const imageSliderRef = createRef<Slider>();
  const thumbnailSliderRef = createRef<Slider>();
  const [currentSlide, setCurrentSlide] = useState<number>(0);
  const [lightboxOpen, setLightboxOpen] = useState<boolean>(false);
  const [lightboxIndex, setLightboxIndex] = useState<number | null>(null);

  const PreviousArrow = (props: CustomArrowProps) => {
    const {onClick} = props;

    return (
      <div className={styles.prevArrow} onClick={onClick}>
        <Arrow angle={0} fill="#ccc" stroke="#fff" />
      </div>
    );
  };

  const NextArrow = (props: CustomArrowProps) => {
    const {onClick} = props;

    return (
      <div className={styles.nextArrow} onClick={onClick}>
        <Arrow angle={180} fill="#ccc" stroke="#fff" />
      </div>
    );
  };

  useEffect(() => {
    if (imageSliderRef.current && thumbnailSliderRef.current) {
      imageSliderRef.current.slickGoTo(currentSlide);
    }
  }, [currentSlide, imageSliderRef, thumbnailSliderRef]);

  useEffect(() => {
    setCurrentSlide(0);
  }, [product]);

  const imageSliderSettings: Settings = {
    dots: false,
    arrows: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    useCSS: false,
    nextArrow: <NextArrow />,
    prevArrow: <PreviousArrow />,
    className: styles.imageSlider,
    afterChange: (current: number) => {
      if (thumbnailSliderRef.current) {
        thumbnailSliderRef.current.slickGoTo(current);
      }
    },
    beforeChange: (current: number, next: number) => {
      setCurrentSlide(next);
    },
  };

  const thumbnailSliderSettings: Settings = {
    dots: false,
    arrows: false,
    infinite: false,
    centerMode: false,
    slidesToShow: 4,
    slidesToScroll: 2,
    focusOnSelect: true,
    speed: 200,
    className: styles.thumbnailSlider,
  };

  const handleOpenLightbox = (index: number) => {
    setLightboxIndex(index);
    setLightboxOpen(true);
  };

  const handleCloseLightbox = () => {
    setLightboxOpen(false);
  };

  return (
    <>
      <ProductLightbox
        product={product}
        isOpen={lightboxOpen}
        index={lightboxIndex}
        handleClose={handleCloseLightbox}
      />
      {product.images && (
        <Slider ref={imageSliderRef} {...imageSliderSettings}>
          {product.images.map((image: string, index) => (
            <div
              key={`image-${index}`}
              className={styles.imageSlide}
              onClick={() => {
                console.log(index);
                handleOpenLightbox(index);
                // if (imageSliderRef.current) {
                //   imageSliderRef.current.slickNext();
                // }
              }}
            >
              <img src={image} alt={product.name} />
            </div>
          ))}
        </Slider>
      )}

      {product.thumbnails && (
        <Slider ref={thumbnailSliderRef} {...thumbnailSliderSettings}>
          {product.thumbnails.map((thumbnail: string, index) => (
            <div
              key={`thumb-${index}`}
              className={(currentSlide === index
                ? [styles.thumbnailSlide, styles.current]
                : [styles.thumbnailSlide]
              ).join(' ')}
              onClick={() => {
                setCurrentSlide(index);
              }}
            >
              <img src={thumbnail} alt={product.name} />
            </div>
          ))}
        </Slider>
      )}
    </>
  );
};
