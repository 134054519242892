import 'i18n';
import {useTranslation} from 'react-i18next';
import {ProductInterface} from 'interfaces/product';
import {gql, QueryResult, useQuery} from '@apollo/client';
import {useState, useEffect} from 'react';
import styles from './product-slider.module.scss';
import Arrow from 'components/button/assets/arrow';
import placeholder from 'components/product/assets/placeholder.png';

export const GET_OFFSET_PRODUCTS = gql`
  query ($categoryId: Int, $name: String, $discount: Boolean) {
    search(categoryId: $categoryId, name: $name, discount: $discount) {
      items {
        id
        name
        article
        currentPrice
        oldPrice
        hasDiscount
        thumbnail
        categoryId
      }
      count
    }
  }
`;

interface ProductSliderProps {
  currentProduct: ProductInterface;
  variables: {
    name?: string;
    categoryId?: number;
    discount?: boolean;
  };
  changeProduct: (product: ProductInterface) => void;
}

export const ProductSlider = ({currentProduct, variables, changeProduct}: ProductSliderProps) => {
  const {t} = useTranslation();
  const [currentOffset, setCurrentOffset] = useState<number | null>(null);
  const limit = 3;

  interface Response {
    search: {
      items: ProductInterface[];
      count: number;
    };
  }

  const sliceArray = () => {
    if (!data) {
      return null;
    }

    if (currentOffset == null) {
      const idx = data.search.items.findIndex((item) => item.id === currentProduct.id);
      setCurrentOffset(idx > 0 ? idx - 1 : 0);
      return data.search.items.slice(idx > 0 ? idx - 1 : 0, idx > 0 ? idx + 2 : 3);
    } else {
      return data.search.items.slice(currentOffset, currentOffset + 3);
    }
  };

  const {data}: QueryResult<Response> = useQuery<Response>(GET_OFFSET_PRODUCTS, {
    variables,
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    setCurrentOffset(null);
  }, [currentProduct]);

  return (
    <>
      {data && (
        <div className={styles.resultsList}>
          {currentOffset !== null && currentOffset > 1 && (
            <div
              className={styles.prevArrow}
              onClick={() => {
                setCurrentOffset(currentOffset - limit);
              }}
            >
              <Arrow angle={90} fill="#ccc" stroke="#fff" />
            </div>
          )}
          {sliceArray()?.map((product: ProductInterface) => (
            <div
              className={(currentProduct?.id === product.id
                ? [styles.result, styles.current]
                : [styles.result]
              ).join(' ')}
              key={product.id}
              onClick={() => changeProduct(product)}
            >
              <div className={styles.product}>
                <div className={styles.image}>
                  {product.hasDiscount && <span className={styles.sale}>{t('product.sale')}</span>}
                  <img src={product.thumbnail ?? placeholder} alt={product.name} width={120} />
                </div>
                <div className={styles.title}>{product.name}</div>
                <div className={styles.price}>
                  {product.hasDiscount ? (
                    <>
                      <span className={styles.currentPrice}>{product.currentPrice}</span>
                      <span className={styles.oldPrice}>{product.oldPrice}</span>
                    </>
                  ) : (
                    <span>{product.currentPrice}</span>
                  )}
                </div>
              </div>
            </div>
          ))}
          {currentOffset !== null && data && currentOffset + limit < data.search.count && (
            <div
              className={styles.nextArrow}
              onClick={() => {
                setCurrentOffset(currentOffset + limit);
              }}
            >
              <Arrow angle={270} fill="#ccc" stroke="#fff" />
            </div>
          )}
        </div>
      )}
    </>
  );
};
