const Home = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 60 50"
      version="1.1"
      id="svg16"
      width="50"
      height="50"
    >
      <defs id="defs20" />
      <g fill="#3f3f3f" id="g6" transform="translate(-208.89746,-191.00725)">
        <path
          id="path2"
          d="m 233.90299,192.29087 -0.10185,0.10411 h -6.9e-4 l -24.90299,23.65462 h 6.27267 l 0.0434,23.67403 h 12.84072 v -12.33599 h 11.89133 v 12.33599 h 12.88453 V 216.0496 h 6.06739 l -24.89759,-23.65462 z"
          fill="#fff"
        />
      </g>
    </svg>
  );
};

export default Home;
