import 'i18n';
import {useTranslation} from 'react-i18next';
import {ProductInterface} from 'interfaces/product';
import {Drawer} from '@blueprintjs/core';
import styles from './product-lightbox.module.scss';
import {MouseEvent, createRef} from 'react';
import {Button} from 'components/button';
import Slider, {CustomArrowProps, Settings} from 'react-slick';
import Arrow from 'components/button/assets/arrow';

interface ProductImageProps {
  product: ProductInterface;
  isOpen: boolean;
  index: number | null;
  handleClose: (ev: MouseEvent<HTMLButtonElement | HTMLDivElement>) => void;
}

export const ProductLightbox = ({product, isOpen, index, handleClose}: ProductImageProps) => {
  const {t} = useTranslation();
  const imageSliderRef = createRef<Slider>();

  const PreviousArrow = (props: CustomArrowProps) => {
    const {onClick} = props;

    return (
      <div className={styles.prevArrow} onClick={onClick}>
        <Arrow angle={0} fill="#ccc" stroke="#fff" />
      </div>
    );
  };

  const NextArrow = (props: CustomArrowProps) => {
    const {onClick} = props;

    return (
      <div className={styles.nextArrow} onClick={onClick}>
        <Arrow angle={180} fill="#ccc" stroke="#fff" />
      </div>
    );
  };

  const imageSliderSettings: Settings = {
    dots: false,
    arrows: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    useCSS: false,
    nextArrow: <NextArrow />,
    prevArrow: <PreviousArrow />,
    className: styles.imageSlider,
    initialSlide: index as unknown as number,
  };

  return (
    <Drawer
      size={`100%`}
      transitionDuration={0}
      hasBackdrop={true}
      isOpen={isOpen}
      className={styles.drawer}
      lazy={true}
      usePortal={true}
    >
      <div className={styles.drawerBody}>
        {product.lightboxImages && (
          <Slider ref={imageSliderRef} {...imageSliderSettings}>
            {product.lightboxImages.map((image: string, idx) => (
              <div
                key={`lightbox-${idx}`}
                className={styles.imageSlide}
                onClick={() => {
                  if (imageSliderRef.current) {
                    imageSliderRef.current.slickNext();
                  }
                }}
              >
                <img src={image} alt={product.name} />
              </div>
            ))}
          </Slider>
        )}
        <div className={styles.drawerFooter}>
          <Button
            text={t('button.back')}
            large={true}
            arrow="left"
            onClick={handleClose}
            inverted={true}
          />
        </div>
      </div>
    </Drawer>
  );
};
