import React, {ReactElement, Suspense} from 'react';
import {client} from './client';
import {ApolloProvider} from '@apollo/client';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import {HomePage} from 'pages/home';
import {Layout} from 'components/layout';
import {CategoryPage} from 'pages/category';
import {SearchPage} from 'pages/search';

export const App = (): ReactElement => {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <ApolloProvider client={client}>
        <Router>
          <Layout>
            <Switch>
              <Route exact path="/" component={HomePage} />
              <Route exact path="/category/:id" component={CategoryPage} />
              <Route exact path="/search/:query" component={SearchPage} />
            </Switch>
          </Layout>
        </Router>
      </ApolloProvider>
    </Suspense>
  );
};

export default App;
