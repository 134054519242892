import {CategoryList} from 'components/category-list';
import {RouteComponentProps} from 'react-router-dom';
import {gql, useQuery, QueryResult} from '@apollo/client';
import {Breadcrumbs} from 'components/breadcrumbs';
import {ProductList} from 'components/product-list';
import {DiscountList} from 'components/discount-list';
import ReactGA from 'react-ga';

export const CategoryPage = ({match}: RouteComponentProps<{id: string}>) => {
  const categoryId = +match.params.id;

  const GET_CATEGORY = gql`
    query ($id: Int!) {
      category(id: $id) {
        id
        name
        productCount
      }
    }
  `;

  interface Response {
    category: {
      id: number;
      name: string;
      productCount: number;
    };
  }

  const {data}: QueryResult<Response> = useQuery<Response>(GET_CATEGORY, {
    variables: {
      id: categoryId,
    },
  });

  if (data) {
    ReactGA.event({
      category: 'Category',
      action: 'view',
      label: data.category.name,
    });
  }

  return (
    <>
      {data && (
        <>
          <Breadcrumbs categoryId={categoryId} />
          {data.category.productCount > 0 ? (
            <ProductList category={data.category} limit={10} />
          ) : (
            <>
              <CategoryList limit={10} parent={data.category.id} />
              <DiscountList limit={50} category={data.category} />
            </>
          )}
        </>
      )}
    </>
  );
};
