import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import enTranslations from './translations/en.json';
import lvTranslations from './translations/lv.json';
import ruTranslations from './translations/ru.json';

// the translations
// (tip move them in a JSON file and import them)
const resources = {
  en: {
    translation: enTranslations,
  },
  lv: {
    translation: lvTranslations,
  },
  ru: {
    translation: ruTranslations,
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: 'lv',

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
