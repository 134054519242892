import React from 'react';
import ReactPaginate from 'react-paginate';
import styles from './pagination.module.scss';

interface PaginationProps {
  pageCount: number;
  handlePageChange: (selectedItem: {selected: number}) => void;
}

export const Pagination = ({pageCount, handlePageChange}: PaginationProps) => {
  return (
    <ReactPaginate
      containerClassName={styles.pagination}
      pageCount={pageCount}
      marginPagesDisplayed={1}
      pageRangeDisplayed={2}
      previousLabel=""
      nextLabel=""
      onPageChange={handlePageChange}
      activeClassName={styles.active}
      pageClassName={styles.page}
      previousClassName={styles.previous}
      nextClassName={styles.next}
    />
  );
};
