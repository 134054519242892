import 'i18n';
import {useTranslation} from 'react-i18next';
import {CategoryInterface} from 'interfaces/category';
import styles from './breadcrumbs.module.scss';
import {gql, useQuery, QueryResult} from '@apollo/client';
import {MouseEvent} from 'react';
import {useHistory} from 'react-router-dom';

interface BreadcrumbsProps {
  categoryId?: number;
  handleClose?: (ev: MouseEvent<HTMLButtonElement | HTMLDivElement | HTMLSpanElement>) => void;
}

export const GET_CATEGORY_TREE = gql`
  query ($id: Int!) {
    category(id: $id) {
      id
      name
      parentCategory {
        id
        name
        parentCategory {
          id
          name
          parentCategory {
            id
            name
          }
        }
      }
    }
  }
`;

export const Breadcrumbs = ({categoryId, handleClose}: BreadcrumbsProps) => {
  const history = useHistory();
  const {t} = useTranslation();
  const crumbs = [];
  let key = 0;

  const handleClick = (event: React.MouseEvent<HTMLSpanElement>) => {
    const id = event.currentTarget.attributes.getNamedItem('data-id')?.value;
    if (id) {
      history.push(`/category/${id}`);
    }
    if (handleClose) {
      handleClose(event);
    }
  };

  interface Response {
    category: {
      id: number;
      name: string;
      productCount: number;
      parentCategory: CategoryInterface;
    };
  }

  const {data}: QueryResult<Response> = useQuery<Response>(GET_CATEGORY_TREE, {
    variables: {
      id: categoryId ? categoryId : 2147483646,
    },
  });

  if (data?.category !== undefined && data?.category !== null) {
    let currentCategory: CategoryInterface = data.category;
    crumbs.push(
      <span
        className={styles.active}
        key={key}
        data-id={currentCategory.id}
        onClick={(event) => {
          handleClick(event);
        }}
      >
        {currentCategory.name}
      </span>,
    );
    key++;
    crumbs.push(<span key={key}>/</span>);
    key++;

    do {
      if (currentCategory.parentCategory) {
        crumbs.push(
          <span
            key={key}
            data-id={currentCategory.parentCategory.id}
            onClick={(event) => {
              handleClick(event);
            }}
          >
            {currentCategory.parentCategory.name}
          </span>,
        );
        key++;
        crumbs.push(<span key={key}>/</span>);
        key++;
        currentCategory = currentCategory.parentCategory;
      }
    } while (currentCategory.parentCategory);
  }

  crumbs.push(
    <span
      key={key}
      onClick={(event) => {
        history.push('/');
        if (handleClose) {
          handleClose(event);
        }
      }}
    >
      {t('breadcrumbs.home')}
    </span>,
  );

  return <div className={styles.breadcrumbs}>{crumbs.reverse()}</div>;
};
