import styles from './category.module.scss';
import {CategoryInterface} from 'interfaces/category';
import {useHistory} from 'react-router-dom';
import Placeholder from './assets/placeholder.png';

export const Category = ({category, count}: {category: CategoryInterface; count: number}) => {
  const history = useHistory();
  let width = 100 / count;
  let height = 500;
  let fontSize = '2rem';

  if (count >= 7) {
    width = 25;
    fontSize = '1.6rem';
  }

  if (count >= 10) {
    width = 20;
    fontSize = '1.4rem';
  }

  if (count > 5) {
    height = 250;
  }

  const cardStyle = {
    width: `${width}%`,
    height: `${height}px`,
    backgroundImage: category.image !== null ? 'url(' + category.image + ')' : Placeholder,
    backgroundPosition: 'center center',
    backgroundSize: 'cover',
    fontSize,
  };

  const handleClick = (cat: CategoryInterface) => {
    history.push(`/category/${cat.id}`);
  };

  return (
    <div className={styles.category} style={cardStyle} onClick={() => handleClick(category)}>
      <div>
        <span>{category.name}</span>
      </div>
    </div>
  );
};
