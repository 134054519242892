import {gql, useQuery, QueryResult} from '@apollo/client';
import {CategoryInterface} from 'interfaces/category';
import styles from './search-categories.module.scss';
import {useHistory} from 'react-router-dom';
import React from 'react';

export const SEARCH_CATEGORIES = gql`
  query ($name: String!, $limit: Int!) {
    categories(name: $name) {
      items(limit: $limit) {
        id
        name
      }
      count
    }
  }
`;

interface SearchCategoriesProps {
  query: string;
  handleClose: (ev: React.MouseEvent<HTMLLIElement>) => void;
}

export const SearchCategories = ({query, handleClose}: SearchCategoriesProps) => {
  const history = useHistory();

  const handleClick = (category: CategoryInterface, event: React.MouseEvent<HTMLLIElement>) => {
    history.push(`/category/${category.id}`);
    handleClose(event);
  };

  interface Response {
    categories: {
      items: {
        id: number;
        name: string;
      }[];
      count: number;
    };
  }

  const {data}: QueryResult<Response> = useQuery<Response>(SEARCH_CATEGORIES, {
    variables: {
      name: query,
      limit: 5,
    },
    fetchPolicy: 'no-cache',
  });

  return (
    <ul className={styles.searchCategories}>
      {data &&
        data.categories.items.map((category: CategoryInterface) => (
          <li key={category.id} onClick={(event) => handleClick(category, event)}>
            {category.name}
          </li>
        ))}
    </ul>
  );
};
