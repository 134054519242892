import 'i18n';
import {useTranslation} from 'react-i18next';
import {ProductInterface} from 'interfaces/product';
import {gql, QueryResult, useQuery} from '@apollo/client';
import styles from './product-details.module.scss';
import {ProductStock} from 'components/product-stock';
import {Button} from 'components/button';
import {ProductImage} from 'components/product-image';

export const GET_PRODUCT = gql`
  query ($id: Int!) {
    product(id: $id) {
      id
      name
      article
      currentPrice
      oldPrice
      hasDiscount
      dimensions
      weight
      description
      images
      thumbnails
      lightboxImages
      stocks {
        amount
        location {
          iln
          name
          address
        }
      }
    }
  }
`;

interface ProductDetailsProps {
  product: ProductInterface;
  HandleClickBack: (ev: React.MouseEvent<HTMLButtonElement>) => void;
}

export const ProductDetails = ({product, HandleClickBack}: ProductDetailsProps) => {
  const {t} = useTranslation();

  interface Response {
    product: ProductInterface;
  }

  const {data}: QueryResult<Response> = useQuery<Response>(GET_PRODUCT, {
    variables: {
      id: product.id,
    },
    fetchPolicy: 'no-cache',
  });

  return (
    <div id="details" className={styles.productDetailsContainer}>
      {data && (
        <>
          <div className={styles.productImages}>
            <ProductImage product={data.product} />
          </div>
          <div className={styles.productDetails}>
            <h3 className={styles.title}>{data.product.name}</h3>
            <div className={styles.article}>
              {t('product.article')} {data.product.article}
            </div>
            <div className={styles.price}>
              {data.product.hasDiscount ? (
                <>
                  <span className={styles.currentPrice}>{data.product.currentPrice}</span>
                  <span className={styles.oldPrice}>{data.product.oldPrice}</span>
                </>
              ) : (
                <span>{data.product.currentPrice}</span>
              )}
              {product.hasDiscount && <span className={styles.sale}>{t('product.sale')}</span>}
            </div>
            <div className={styles.scrollContainer}>
              <ProductStock product={data.product} />
              {data.product.description && (
                <>
                  <h4>{t('product.description')}</h4>
                  <div
                    className={styles.description}
                    dangerouslySetInnerHTML={{__html: data.product.description}}
                  />
                </>
              )}
              {(data.product.weight || data.product.dimensions) && (
                <>
                  <h4>{t('product.specifications')}</h4>
                  <div className={styles.specifications}>
                    {data.product.weight && (
                      <div>
                        <span>{t('product.weight')}</span>
                        {data.product.weight} kg
                      </div>
                    )}
                    {data.product.dimensions && (
                      <div>
                        <span>{t('product.dimensions')}</span>
                        {data.product.dimensions} mm
                      </div>
                    )}
                  </div>
                </>
              )}
            </div>
            <Button
              text={t('button.back')}
              large={true}
              arrow="left"
              onClick={HandleClickBack}
              inverted={true}
            />
          </div>
        </>
      )}
    </div>
  );
};
