import 'i18n';
import {useTranslation} from 'react-i18next';
import {useState, CSSProperties} from 'react';
import styles from './category-list.module.scss';
import {gql, useQuery, QueryResult} from '@apollo/client';
import {CategoryInterface} from 'interfaces/category';
import {Category} from 'components/category';
import Arrow from 'components/button/assets/arrow';

export const GET_CATEGORIES = gql`
  query ($limit: Int, $offset: Int, $parent: Int) {
    categories(parent: $parent) {
      items(limit: $limit, offset: $offset) {
        id
        name
        image
      }
      count
    }
  }
`;

interface CategoryListProps {
  limit: number;
  parent?: number;
}

export const CategoryList = ({limit, parent}: CategoryListProps) => {
  const {t} = useTranslation();
  const [offset, setOffset] = useState<number>(0);

  interface Response {
    categories: {
      items: {
        id: number;
        name: string;
      }[];
      count: number;
    };
  }

  const {data}: QueryResult<Response> = useQuery<Response>(GET_CATEGORIES, {
    variables: {
      limit,
      offset,
      parent,
    },
    fetchPolicy: 'no-cache',
  });

  const nextPage = () => {
    setOffset(offset + limit);
  };

  const prevPage = () => {
    setOffset(offset - limit);
  };

  const arrowContainerStyles: CSSProperties = {
    width: '100%',
    display: 'flex',
    justifyContent: 'left',
    alignItems: 'center',
    marginTop: 20,
  };
  return (
    <>
      <div className={styles.categoryList}>
        {data &&
          data.categories.items.map((category: CategoryInterface) => (
            <Category key={category.id} category={category} count={data.categories.count} />
          ))}
      </div>
      {data && data.categories.count > limit && (
        <div style={arrowContainerStyles}>
          {offset - limit >= 0 && (
            <div className={styles.prevArrow} onClick={prevPage}>
              <Arrow stroke="white" fill="#8e3193" width={50} height={50} strokeWidth={0} />
            </div>
          )}
          {offset < limit && (
            <div className={styles.nextArrow} onClick={nextPage}>
              <Arrow
                stroke="white"
                fill="#8e3193"
                angle={180}
                width={50}
                height={50}
                strokeWidth={0}
              />
            </div>
          )}
        </div>
      )}
      {data && data.categories.count === 0 && (
        <div className={styles.emptyCategory}>{t('category.empty')}</div>
      )}
    </>
  );
};
