import {CategoryInterface} from 'interfaces/category';
import {gql, QueryResult, useQuery} from '@apollo/client';
import styles from './product-list.module.scss';
import {ProductInterface} from 'interfaces/product';
import {Product} from 'components/product';
import {useState} from 'react';
import {Pagination} from 'components/pagination';
import {ProductDrawer} from 'components/product-drawer';

export const GET_PRODUCTS = gql`
  query ($categoryId: Int, $limit: Int, $offset: Int) {
    search(categoryId: $categoryId, limit: $limit, offset: $offset) {
      items {
        id
        name
        article
        currentPrice
        oldPrice
        hasDiscount
        thumbnail
        categoryId
      }
      count
    }
  }
`;

export const ProductList = ({category, limit}: {category: CategoryInterface; limit: number}) => {
  const [offset, setOffset] = useState<number>(0);
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
  const [pageCount, setPageCount] = useState<number>(-1);
  const [openProduct, setOpenProduct] = useState<ProductInterface | undefined>(undefined);

  interface Response {
    search: {
      items: ProductInterface[];
      count: number;
    };
  }

  const handlePageChange = (selectedItem: {selected: number}) => {
    setOffset(limit * selectedItem.selected);
  };

  const {data}: QueryResult<Response> = useQuery<Response>(GET_PRODUCTS, {
    variables: {
      categoryId: category.id,
      limit,
      offset,
    },
    fetchPolicy: 'no-cache',
  });

  if (data && pageCount === -1) {
    setPageCount(Math.ceil(data.search.count / limit));
  }

  const handleOpenDrawer = (product: ProductInterface) => {
    setOpenProduct(product);
    setDrawerOpen(true);
  };

  return (
    <>
      <div className={styles.header}>
        <div className={styles.headerContainer}>
          <h1>{category.name}</h1>
          {pageCount > 1 && (
            <Pagination pageCount={pageCount} handlePageChange={handlePageChange} />
          )}
        </div>
      </div>
      <div className={styles.productList}>
        {data &&
          data.search.items.map((product: ProductInterface) => (
            <Product
              key={product.id}
              product={product}
              handleClick={() => handleOpenDrawer(product)}
            />
          ))}
        {openProduct && (
          <ProductDrawer
            isOpen={drawerOpen}
            handleClose={() => setDrawerOpen(false)}
            openProduct={openProduct}
          />
        )}
      </div>
    </>
  );
};
