import 'i18n';
import {useTranslation} from 'react-i18next';
import {ProductInterface} from 'interfaces/product';
import styles from './product-stock.module.scss';
import {ProductStockInterface} from 'interfaces/product-stock';
import {useEffect, useState} from 'react';
import Logo from './assets/logo.png';

export const ProductStock = ({product}: {product: ProductInterface}) => {
  const {t} = useTranslation();
  const stocks = product.stocks;
  stocks?.sort((a: ProductStockInterface, b: ProductStockInterface) => {
    return a.location.name.localeCompare(b.location.name);
  });
  const [localStock, setLocalStock] = useState<ProductStockInterface | null>(null);
  const [showAll, setShowAll] = useState<boolean>(false);
  const storedLocation = localStorage.getItem('location');

  useEffect(() => {
    const location = stocks?.filter((stock) => {
      return stock.location.iln === storedLocation;
    })[0];
    if (typeof location !== 'undefined') {
      setLocalStock(location);
    }
  }, [stocks, storedLocation]);

  return (
    <>
      {showAll && stocks && (
        <>
          <h4>{t('product.availability_all')}</h4>
          {stocks.map((stock) => (
            <div key={stock.location.iln} className={styles.productStock}>
              <span className={styles.logo}>
                <img alt="majaiundarzam.lv" src={Logo} />
              </span>
              <span className={styles.location}>{stock.location.name}</span>
              <span className={styles.address}>{stock.location.address}</span>
              <span className={styles.amount}>{stock.amount}</span>
            </div>
          ))}
          <span className={styles.toggle} onClick={() => setShowAll(false)}>
            {t('product.availability')}
          </span>
        </>
      )}
      {!showAll && localStock && (
        <>
          <h4>{t('product.availability')}</h4>
          <div className={styles.productStock}>
            <span className={styles.logo}>
              <img alt="majaiundarzam.lv" src={Logo} />
            </span>
            <span className={styles.location}>{localStock.location.name}</span>
            <span className={styles.address}>{localStock.location.address}</span>
            <span className={styles.amount}>{localStock.amount}</span>
          </div>
          <span className={styles.toggle} onClick={() => setShowAll(true)}>
            {t('product.availability_all')}
          </span>
        </>
      )}
    </>
  );
};
