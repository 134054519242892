import {Drawer} from '@blueprintjs/core';
import styles from './product-drawer.module.scss';
import {ProductInterface} from 'interfaces/product';
import {gql, QueryResult, useQuery} from '@apollo/client';
import {MouseEvent, useState, useEffect} from 'react';
import {ProductDetails} from 'components/product-details';
import {ProductSlider} from 'components/product-slider';
import {Breadcrumbs} from 'components/breadcrumbs';
import ReactGA from 'react-ga';

interface ProductDrawerProps {
  isOpen: boolean;
  handleClose: (ev: MouseEvent<HTMLButtonElement | HTMLDivElement | HTMLSpanElement>) => void;
  openProduct: ProductInterface;
  query?: string;
  discount?: boolean;
}

export const GET_ALL_PRODUCTS = gql`
  query ($categoryId: Int, $name: String) {
    search(categoryId: $categoryId, name: $name) {
      items {
        id
      }
    }
  }
`;

export const ProductDrawer = ({
  isOpen,
  handleClose,
  openProduct,
  query,
  discount,
}: ProductDrawerProps) => {
  const [currentProduct, setCurrentProduct] = useState<ProductInterface | null>(null);
  let variables;

  const changeProduct = (product: ProductInterface) => {
    setCurrentProduct(product);
  };

  useEffect(() => {
    changeProduct(openProduct);
  }, [openProduct]);

  interface Response {
    search: {
      items: ProductInterface[];
    };
  }

  if (query) {
    variables = {
      name: query,
    };
  } else if (discount) {
    variables = {
      discount: true,
    };
  } else {
    variables = {
      categoryId: openProduct.categoryId,
    };
  }

  const {data}: QueryResult<Response> = useQuery<Response>(GET_ALL_PRODUCTS, {
    variables,
    fetchPolicy: 'no-cache',
  });

  ReactGA.event({
    category: 'Product',
    action: 'view',
    label: openProduct.name,
    value: (openProduct.article as unknown as number) * 1,
  });

  return (
    <Drawer
      size={`100%`}
      position="bottom"
      transitionDuration={0}
      hasBackdrop={false}
      isOpen={isOpen}
      className={styles.drawer}
      lazy={true}
      usePortal={true}
    >
      <div className={styles.drawerHeader} onClick={handleClose} />
      <div className={styles.drawerBreadcrumbs}>
        <Breadcrumbs categoryId={currentProduct?.categoryId} handleClose={handleClose} />
      </div>
      <div className={styles.drawerBody}>
        {data && currentProduct && (
          <>
            <div className={styles.drawerDetails}>
              <ProductDetails product={currentProduct} HandleClickBack={handleClose} />
            </div>
            <div className={styles.drawerSlider}>
              <ProductSlider
                currentProduct={currentProduct}
                variables={variables}
                changeProduct={changeProduct}
              />
            </div>
          </>
        )}
      </div>
    </Drawer>
  );
};
