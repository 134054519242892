import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {DEV} from './constants';
import './i18n';
import './scss/app.scss';
import {timer} from 'reset';
import ReactGA from 'react-ga';

ReactGA.initialize('UA-722131-12', {
  debug: true,
});

ReactDOM.render(<App />, document.getElementById('root'));

timer();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
if (DEV) {
  reportWebVitals(console.log);
}
