import {gql, useQuery, QueryResult} from '@apollo/client';
import {LocationInterface} from 'interfaces/location';
import styles from './location-list.module.scss';

export const GET_LOCATIONS = gql`
  query {
    locations {
      iln
      name
    }
  }
`;

export const LocationList = () => {
  const setLocation = (iln: string) => {
    localStorage.setItem('location', iln);
  };

  interface Response {
    locations: LocationInterface[];
  }

  const {data}: QueryResult<Response> = useQuery<Response>(GET_LOCATIONS, {
    fetchPolicy: 'no-cache',
  });

  return (
    <div className={styles.locationList}>
      {data &&
        data.locations.map((location: LocationInterface) => (
          <button key={location.iln} onClick={() => setLocation(location.iln)}>
            {location.name} [{location.iln}]
          </button>
        ))}
    </div>
  );
};
