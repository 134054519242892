import {CategoryList} from 'components/category-list';
import {Breadcrumbs} from 'components/breadcrumbs';
import {DiscountList} from 'components/discount-list';
import {LocationList} from 'components/location-list';

export const HomePage = () => {
  const location = localStorage.getItem('location');

  return (
    <>
      <Breadcrumbs />
      {location ? (
        <>
          <CategoryList limit={5} />
          <DiscountList limit={50} />
        </>
      ) : (
        <LocationList />
      )}
    </>
  );
};
